import React from "react";

import CheaperImage from "../assets/images/Cheaper.svg";
import FasterImage from "../assets/images/Faster.svg";
import SecureImage from "../assets/images/Secure.svg";

import styles from "../styles/why_use.module.css";

export default function WhyUseCBDCXComponent() {
  return (
    <section className={styles["why__use_section"]}>
      <div className={styles["container"]}>
        <div className={styles["why__use_container"]}>
          <h1 className={styles["why__use_title"]}>How CBDCX is Revolutionizing Cross-Border Trade?</h1>
          <div className={styles["transaction__boxes"]}>
            <div className={styles["transacton__box"]}>
              <img src={CheaperImage} alt="Cheaper" width={62} height={62} />
              <h1>Up to 5X Cheaper</h1>
              <p>Get competitive, bank-beating exchange rates and low fees.</p>
            </div>
            <div className={styles["transacton__box"]}>
              <img src={FasterImage} alt="Fees" width={62} height={62} />
              <h1>Up to 12X Faster</h1>
              <p>Most transactions are completed instantly.</p>
            </div>
            <div className={styles["transacton__box"]}>
              <img
                src={SecureImage}
                alt="Secure"
                width={62}
                height={62}
              />
              <h1>Secure and Reliable</h1>
              <p>
              Regulated, licensed, and built on industry-leading technology.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

import React from "react";

import styles from "../styles/landing.module.css";

export default function LandingComponent() {
  const statistics = [
    {
      title: "countries where you can open accounts with local bank details",
      value: "60+",
    },
    {
      title: "countries to which you can make local transfers",
      value: "110+",
    },
    {
      title: "global payments processed annually",
      value: "$60B+",
    },
    {
      title: "countries from which you can accept payments",
      value: "180+",
    },
  ];

  return (
    <section className={styles["landing__section"]}>
      <div className={styles["container"]}>
        <div className={styles["landing__container"]}>
          <div className={styles["landing__content"]}>
            <h1>Powering the Future of <br />Cross Border Payments</h1>
            <ul className={styles["span__options"]}>
              <li>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                  >
                    <defs>
                      <clipPath>
                        <rect width="32" height="32"></rect>
                      </clipPath>
                    </defs>
                    <g id="instarem-fast-icon">
                      <g id="Fast" transform="translate(-46.25 -28.631)">
                        <path
                          id="Path_1327"
                          data-name="Path 1327"
                          d="M76.172,48.12,63.34,66.088l2.567-11.55H55.64L68.472,36.57,65.907,48.12Z"
                          transform="translate(-3.422 -5.456)"
                          fill="#48f9fe"
                          opacity="0.75"
                        ></path>
                        <path
                          id="Path_1328"
                          data-name="Path 1328"
                          d="M71.782,41.12,58.95,59.088l2.567-11.55H51.25L64.084,29.57,61.517,41.12Z"
                          transform="translate(0 0)"
                          fill="none"
                          stroke="#000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </span>
                <p>Fast</p>
              </li>
              <li>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                  >
                    <defs>
                      <clipPath>
                        <rect width="32" height="32"></rect>
                      </clipPath>
                    </defs>
                    <g id="instarem-secure-icon">
                      <g id="Secure" transform="translate(-46.33 -37.41)">
                        <path
                          id="Path_1323"
                          data-name="Path 1323"
                          d="M68,74.437a.484.484,0,0,1-.248-.071l-.8-.485c-1.374-.828-2.794-1.685-4.127-2.619a18.031,18.031,0,0,1-3.8-3.4c-2.026-2.508-2.97-5.668-2.97-9.947V48.99a.478.478,0,0,1,.462-.48A20.5,20.5,0,0,0,67.7,44.246a.475.475,0,0,1,.589-.008,20.3,20.3,0,0,0,11.2,4.271.478.478,0,0,1,.462.48v8.921c0,2.96-.3,6.747-2.617,9.755a16.376,16.376,0,0,1-3.791,3.41c-1.324.912-2.728,1.753-4.087,2.566-.4.242-.808.482-1.21.727a.493.493,0,0,1-.25.071h0Z"
                          transform="translate(-5.023 -5.03)"
                          fill="#48f9fe"
                          fill-rule="evenodd"
                          opacity="0.75"
                        ></path>
                        <g
                          id="Group_6874"
                          data-name="Group 6874"
                          transform="translate(49.33 37.41)"
                        >
                          <path
                            id="Path_1324"
                            data-name="Path 1324"
                            d="M61.734,68.867a.5.5,0,0,1-.258-.073l-.834-.505c-1.427-.861-2.9-1.75-4.284-2.72a18.7,18.7,0,0,1-3.945-3.534c-2.1-2.6-3.084-5.885-3.084-10.328V42.444a.5.5,0,0,1,.48-.5,21.284,21.284,0,0,0,11.614-4.428.5.5,0,0,1,.311-.109.49.49,0,0,1,.3.1,21.079,21.079,0,0,0,11.632,4.435.5.5,0,0,1,.48.5V51.7c0,3.074-.308,7-2.718,10.129a16.943,16.943,0,0,1-3.935,3.541c-1.374.947-2.831,1.819-4.243,2.665-.419.25-.839.5-1.255.755a.5.5,0,0,1-.258.073h0Zm-.351-30.323A24.141,24.141,0,0,1,50.691,42.7l-.556.063v8.883c0,4.367.9,7.429,2.917,9.924A18.245,18.245,0,0,0,56.892,65c1.422,1,2.933,1.907,4.392,2.789l.45.273.892-.533c1.445-.864,2.94-1.758,4.355-2.733a16.218,16.218,0,0,0,3.8-3.4c2.263-2.938,2.554-6.747,2.554-9.74V42.765l-.558-.061A23.476,23.476,0,0,1,62.1,38.539l-.361-.25-.359.255Z"
                            transform="translate(-49.33 -37.41)"
                            fill="#231f20"
                            stroke="#000"
                            stroke-width="0.1"
                            fill-rule="evenodd"
                          ></path>
                          <path
                            id="Path_1325"
                            data-name="Path 1325"
                            d="M80.588,89.625a.414.414,0,0,1-.293-.116l-3.284-3.284a.353.353,0,0,1-.091-.409.472.472,0,0,1,.407-.293.369.369,0,0,1,.265.121l2.988,2.988,6.507-6.709a.367.367,0,0,1,.263-.124.462.462,0,0,1,.4.29.362.362,0,0,1-.086.419l-6.789,7a.388.388,0,0,1-.283.114Z"
                            transform="translate(-69.929 -70.588)"
                            fill="#231f20"
                            stroke="#000"
                            stroke-width="0.1"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
                <p>Secure</p>
              </li>
              <li>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                  >
                    <defs>
                      <clipPath>
                        <rect width="32" height="32"></rect>
                      </clipPath>
                    </defs>
                    <g id="instarem-low-cost-icon">
                      <g
                        id="Low_Cost"
                        data-name="Low Cost"
                        transform="translate(-35.38 -32.775)"
                      >
                        <path
                          id="Path_1326"
                          data-name="Path 1326"
                          d="M67.9,50.452a11.4,11.4,0,0,1,22.8,0c0,4.9-4.12,9.4-8.459,11.014-1.236.459-2.185-1.948-2.92-1.932-1.332-.03-2.792,1.976-3.372,1.818a11.4,11.4,0,0,1-8.051-10.9Z"
                          transform="translate(-24.329 -4.352)"
                          fill="#48f9fe"
                          opacity="0.7"
                        ></path>
                        <path
                          id="Shape_511"
                          data-name="Shape 511"
                          d="M137.56,150.345h5.277V145.1"
                          transform="translate(-78.098 -86.209)"
                          fill="none"
                          stroke="#000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1"
                        ></path>
                        <path
                          id="Shape_528"
                          data-name="Shape 528"
                          d="M66.127,54.153c-9.565-7.017-3.4-22.315,8.42-20.609a11.481,11.481,0,0,1,6.684,19.24"
                          transform="translate(-19.335)"
                          fill="none"
                          stroke="#000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1"
                        ></path>
                        <g
                          id="Group_6875"
                          data-name="Group 6875"
                          transform="translate(51.041 39.218)"
                        >
                          <path
                            id="Shape_185"
                            data-name="Shape 185"
                            d="M111.54,60.185V58.86"
                            transform="translate(-109.055 -58.86)"
                            fill="none"
                            stroke="#000"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1"
                          ></path>
                          <path
                            id="Shape_186"
                            data-name="Shape 186"
                            d="M105.122,64.67h-2.69a1.777,1.777,0,0,0-.664,3.429l2.735,1.1a1.778,1.778,0,0,1-.662,3.429h-2.7"
                            transform="translate(-100.648 -63.345)"
                            fill="none"
                            stroke="#000"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1"
                          ></path>
                          <path
                            id="Shape_187"
                            data-name="Shape 187"
                            d="M111.54,100.865V99.54"
                            transform="translate(-109.055 -90.26)"
                            fill="none"
                            stroke="#000"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1"
                          ></path>
                        </g>
                        <path
                          id="Shape_510"
                          data-name="Shape 510"
                          d="M36.38,104.5l11.039,9.814a1.318,1.318,0,0,0,1.7.043l4.836-3.869a1.32,1.32,0,0,1,1.725.066l9.059,8.454"
                          transform="translate(0 -54.871)"
                          fill="none"
                          stroke="#000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </span>
                <p>Low-Cost</p>
              </li>
            </ul>
            <p>
              CBDCX is building a platform for cross-border payments powered by Central Bank Digital Currencies (CBDCs). We're turning the world of financial technology by revolutionizing the way businesses and individuals conduct international transactions and remittances, making it faster, cheaper and more secure than ever before.

            </p>
          </div>
          <div className={styles["landing__stats"]}></div>
        </div>
      </div>
    </section>
  );
}
